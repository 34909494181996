export const patientES: any = {
  mainMenu: "Main Navigational Menu Consumer GCSO - Retina Spain",
  banner: "Banner - HTML - ES - DTC - Retina Spain",
  footer: "Footer Consumer - Retina Spain",
  exitPopup: "Exit Popup - DTC - Retina Spain",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
  topNav: "Top Navigational Menu Consumer - Retina Spain",
  footerRef: "Reference - ES - DTC - Retina Spain",
  faqSection: "FAQ Section HTML - ES - DTC - Retina Spain",
  learnMore: "Learn More HTML - ES - DTC - Retina Spain",
  scienceAdv: "Science is advancing - ES - DTC - Retina Spain",
  whenYouTest: "When You Test - ES - DTC - Retina Spain",
  footerCallout: "Footer Callout - ES - DTC - Retina Spain",
  siteLogo: "Site logos - Retina Spain",
  backToTopGTM: "back to top - emerging science",
  footerClassName: "emerging-science-footer"
}
